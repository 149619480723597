import { Component, Vue, Model, Prop } from "vue-property-decorator";
import debounce from "lodash.debounce";

type TValue = string | number;

@Component
export default class MultiSelect extends Vue {
  @Model("input", { type: Array, required: true })
  private readonly selectedItems!: TValue[];

  @Prop({ type: Boolean, default: false })
  private readonly hiddenActivator!: boolean;
  @Prop({ type: Boolean, default: undefined })
  private readonly hiddenSearch?: boolean;
  @Prop({ type: Boolean, default: false })
  private readonly hiddenSelectedInfo!: boolean;
  @Prop({ type: Boolean, default: false })
  private readonly hiddenSelectAllControls!: boolean;
  @Prop({ type: Array, required: true }) private readonly items!: Record<
    string,
    string | number
  >[];
  @Prop({ type: String }) private readonly inputLabel?: string;
  @Prop({ type: String }) private readonly inputIcon?: string;

  @Prop({ type: Number })
  private readonly selectedItemsLength?: number;
  @Prop({ type: Number })
  private readonly totalItemsLength?: number;
  @Prop({ type: Number, default: 45 })
  private readonly itemHeight!: number;
  @Prop({ type: Number, default: 160 })
  private readonly maxHeight!: number;
  @Prop({ type: Number, default: 235 })
  private readonly minWidth!: number;

  @Prop({ type: String, default: "text" })
  private readonly itemText!: string;
  @Prop({ type: String, default: "value" })
  private readonly itemValue!: string;
  @Prop({ type: Boolean, default: false }) private readonly loading!: boolean;
  @Prop({ type: Array, default: () => [] })
  private readonly searchByFields!: string[];

  private debouncedSearchInput!: ReturnType<typeof debounce>;

  private showedList = false;
  private searchInput = "";
  private searching = false;

  private get localHiddenSearch() {
    return this.hiddenSearch ?? this.items.length < this.virtualScrollBench + 2;
  }

  private get filteredItems() {
    const searchInput = this.searchInput.toLowerCase();
    const searchByFields = this.searchByFields.concat(this.itemText);

    if (searchInput.length) {
      return this.items.filter((item) => {
        return searchByFields.some((searchByField) => {
          const searchByFieldValue = item[searchByField];

          if (
            typeof searchByFieldValue !== "string" &&
            typeof searchByFieldValue !== "number"
          ) {
            return false;
          }

          const text = searchByFieldValue.toString().trim().toLowerCase();

          return (
            text.includes(searchInput) || (text && searchInput.includes(text))
          );
        });
      });
    }

    return this.items;
  }

  private get localSelectedItemsLength() {
    return this.selectedItemsLength ?? this.selectedItems.length;
  }

  private get localTotalItemsLength() {
    return this.totalItemsLength ?? this.items.length;
  }

  private get selectedAll() {
    return this.localSelectedItemsLength === this.localTotalItemsLength;
  }

  private set selectedAll(selected) {
    if (selected) {
      this.$emit(
        "input",
        this.items.map((item) => item[this.itemValue])
      );
    } else {
      this.$emit("input", []);
    }
  }

  private get virtualScrollBench() {
    return Math.ceil(this.maxHeight / this.itemHeight) + 1;
  }

  private selectAll() {
    this.toggleSelectAll(true);
  }

  private toggleSelectAll(selectedAll = !this.selectedAll) {
    this.onUpdateSearchInput("");
    this.selectedAll = selectedAll;
  }

  private toggleItem(
    newSelectedItem: TValue,
    selected = !this.isSelectedItem(newSelectedItem)
  ) {
    if (selected) {
      this.$emit("input", [...this.selectedItems, newSelectedItem]);
    } else {
      this.$emit(
        "input",
        this.selectedItems.filter(
          (selectedItem) => selectedItem !== newSelectedItem
        )
      );
    }
  }

  private isSelectedItem(selectedItem: TValue) {
    return this.selectedItems.includes(selectedItem);
  }

  private onUpdateSearchInput(value: string | null) {
    this.searching = true;
    value = value?.trim() || "";

    this.debouncedSearchInput(value);
    this.$emit("update:search-input", value);
  }

  private onUpdateShowedMenu(showed: boolean) {
    this.showedList = showed;
    this.$emit("update:showed-menu", showed);
  }

  private onMouseEnterItem<T>(item: T, event: MouseEvent) {
    this.$emit("mouseenter:item", item, event);
  }

  private onMouseLeaveItem<T>(item: T, event: MouseEvent) {
    this.$emit("mouseleave:item", item, event);
  }

  private created() {
    this.debouncedSearchInput = debounce(
      (value: string) => {
        this.searchInput = value;
        this.searching = false;
      },
      400,
      { maxWait: 400 }
    );

    this.$watch(
      () => {
        return this.hiddenActivator;
      },
      (hiddenActivator) => {
        this.showedList = hiddenActivator;
      },
      {
        immediate: true,
      }
    );
  }
}
